(function (angular, _) {
    const MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpMultipleNewFileUpload', hpMultipleNewFileUploadDirective);
    hpMultipleNewFileUploadDirective.$inject = [];
    function hpMultipleNewFileUploadDirective () {
        return {
            restrict: 'E',
            template: `
                <div>
                    <hp-new-file-upload model="newUpload" on-upload="addUpload()"></hp-new-file-upload>
                    <br style="clear: both;"/>
                    <div ng-repeat="upload in uploads track by $index" style="clear: both;"><!--
                     --><hp-new-file-upload model="upload" on-remove="onRemove({$index: $index})" max-filename-display="maxFilenameDisplay"></hp-new-file-upload><!--
                 --></div>
                </div>
            `,
            scope: {
                parentCollection: '<',
                parentKey: '<',
                maxFilenameDisplay: '<'
            },
            replace: true,
            controller: function ($scope) {
                let lastSavedUploads = $scope.parentCollection[$scope.parentKey];
                try {
                    $scope.uploads = JSON.parse(lastSavedUploads);
                } catch (err) {
                    $scope.uploads = [];
                }

                $scope.newUpload = {};

                $scope.addUpload = () => {
                    $scope.uploads.push($scope.newUpload);
                    $scope.newUpload = {};
                };

                $scope.onRemove = ({$index}) => {
                    $scope.uploads.splice($index, 1);
                };

                // the following watches are required to convert between JSON strings and JavaScript objects,
                // and keep the saved and interactive versions synchronized in both directions

                $scope.$watch('uploads', () => {
                    lastSavedUploads = JSON.stringify($scope.uploads);
                    $scope.parentCollection[$scope.parentKey] = lastSavedUploads;
                }, true);

                $scope.$watch('parentCollection[parentKey]', () => {
                    if ($scope.parentCollection[$scope.parentKey] !== lastSavedUploads) {
                        $scope.uploads = JSON.parse($scope.parentCollection[$scope.parentKey]);
                    }
                }, true);
            }
        };
    }
})(window.angular, window._);
